import { useMemo } from "react";

import { fetcher } from "@utils/swrUtils";
import useSWR from "swr";

import { CountriesResponse } from "@countries/models/CountriesResponse";
import { countryEndpoints } from "./endpoints";

export function useGetCountries() {
  const url = countryEndpoints.countries();

  const { data, error, isLoading } = useSWR<CountriesResponse, Error>(
    url,
    fetcher
  );

  return useMemo(
    () => ({ countries: data?.countries, isLoading, error }),
    [data, isLoading, error]
  );
}
