import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  AccountInfo,
  InteractionStatus,
  RedirectRequest,
  SilentRequest,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

import useIdToken from "@hooks/useIdToken";
import ErrorPage from "@pages/ErrorPage";
import LoadingPage from "@pages/LoadingPage";
import { addUserRequestHeadersInterceptor, ejectRequestInterceptor } from "@utils/axiosInstance";
import { loginRequest, tokenRequest } from "../../authConfig";
import { UserContext } from "./UserContext";
import { User } from "./models/User";
import { getIdTokenHint, getUserRoleName } from "./services/userService";

interface Props {
  children: ReactNode;
}

const defaultLanguage = "en";

const UserContextProvider: FunctionComponent<Props> = ({ children }) => {
  const { instance, inProgress } = useMsal();
  const account = instance.getActiveAccount();

  const { userId, userName, language, organizationId, userEmail } = useIdToken();

  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const { t, i18n } = useTranslation(undefined, { keyPrefix: "account" });

  const getAccessToken = useCallback(
    async (forceRefresh?: boolean): Promise<string> => {
      const account = instance.getActiveAccount();
      if (!account) throw Error("No active account");

      const request: SilentRequest = {
        ...tokenRequest,
        forceRefresh: forceRefresh ?? false,
        account: account,
      };

      let accessToken = "";
      try {
        const result = await instance.acquireTokenSilent(request);
        accessToken = result.accessToken;
      } catch (error) {
        console.error(error);
      }

      if (!accessToken || accessToken === "") await instance.logoutRedirect();

      return accessToken;
    },
    [instance]
  );

  const switchOrganization = useCallback(
    async (organizationId: string): Promise<void> => {
      const accessToken = await getAccessToken();

      const idTokenHint = await getIdTokenHint(accessToken, organizationId);
      if (!idTokenHint) {
        throw new Error("IdTokenHint is NULL");
      }

      const account = instance.getActiveAccount();

      const request: RedirectRequest = {
        ...loginRequest,
        account: account!,
        extraQueryParameters: {
          id_token_hint: idTokenHint,
        },
      };
      await instance.acquireTokenRedirect(request);
    },
    [getAccessToken, instance]
  );

  useEffect(() => {
    let id: number;
    const initialize = async (account: AccountInfo | null) => {
      if (account === null || !userId) {
        setUser(undefined);
        return;
      }

      const accessToken = await getAccessToken(true);

      const roleName = await getUserRoleName(accessToken);

      setUser(
        new User(
          userId,
          userName ?? "",
          roleName ?? "",
          language ?? defaultLanguage,
          userEmail ?? ""
        )
      );
      id = addUserRequestHeadersInterceptor(language ?? defaultLanguage, () => getAccessToken());
    };
    if (inProgress !== InteractionStatus.None) {
      console.log("Initialize userContext: Busy");
      return;
    }

    setIsLoading(true);
    initialize(account)
      .catch(() => setError(t("signin.user.load.error.message")))
      .finally(() => setIsLoading(false));

    return () => {
      ejectRequestInterceptor(id);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, organizationId, inProgress]);

  useEffect(() => {
    const initialize = async (user: User | undefined) => {
      const userLanguage = user?.language ?? defaultLanguage;

      await i18n.changeLanguage(userLanguage);
    };

    initialize(user).catch((reason) => console.error(reason));
  }, [user, i18n]);

  const contextValue = useMemo(
    () => ({
      user: user!,
      getAccessToken,
      switchOrganization,
    }),
    [user, getAccessToken, switchOrganization]
  );

  const memoizedChildren = useMemo(
    () => <>{children}</>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (isLoading) return <LoadingPage />;

  if (error) return <ErrorPage message={error} />;

  if (!user) return <LoadingPage />;

  return (
    <UserContext.Provider value={contextValue}>
      {memoizedChildren}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
