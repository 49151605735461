import { AxiosError } from "axios";
import { axiosInstance } from "./axiosInstance";

export const fetcher = async (url: string) => {
  const response = await axiosInstance
    .get(url)
    .catch(function (error: AxiosError) {
      throw new Error(error.message);
    });

  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  if (response.data?.errorMessage) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    throw new Error(response.data.errorMessage);
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return response.data;
};
