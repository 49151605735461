import { useMemo } from "react";

import useSWR from "swr";

import { applicationEndpoints } from "@api/Endpoints";
import { ApplicationUser } from "@applications/models/ApplicationUser";
import { ApplicationUsersResponse } from "@applications/models/ApplicationUsersResponse";
import { PropertyType, SortDirection, sortValues } from "@utils/sortUtil";
import { fetcher } from "@utils/swrUtils";

export function useGetApplicationsUsers(applicationId: string) {
  const url = applicationEndpoints.applicationUsers(applicationId);
  const { data, error, isLoading } = useSWR<ApplicationUsersResponse, Error>(
    url,
    fetcher
  );

  return useMemo(
    () => ({ applicationUsers: data?.users, isLoading, error }),
    [data, isLoading, error]
  );
}

export function filter(
  users: ApplicationUser[] | undefined,
  value: string | undefined
): ApplicationUser[] {
  if (!users) return [];

  if (!value) return users;

  return users.filter(
    (item) =>
      item.displayName.toLocaleLowerCase().includes(value) ||
      item.email.toLocaleLowerCase().includes(value) ||
      item.applicationRoles.join(",").toLocaleLowerCase().includes(value)
  );
}

export function sort(
  users: ApplicationUser[] | undefined,
  activeSort: string | undefined,
  sortDirection: SortDirection
): ApplicationUser[] {
  return sortValues(users, activeSort, sortDirection, getSortValues);
}
function getSortValues(
  activeSort: string,
  a: ApplicationUser,
  b: ApplicationUser
) {
  let valueA: PropertyType;
  let valueB: PropertyType;
  switch (activeSort) {
    case "name":
      valueA = a.displayName;
      valueB = b.displayName;
      break;
    case "email":
      valueA = a.email;
      valueB = b.email;
      break;
    case "role":
      valueA = a.applicationRoles.join(",");
      valueB = b.applicationRoles.join(",");
      break;
  }
  return { valueA, valueB };
}
