import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";
import ProductCard from "@store/products/components/ProductCard";
import { Product } from "@store/products/models/Product";
import { filter, useGetAllProducts } from "@store/products/services/ProductService";

interface Props {
  searchValue: string;
}

const ProductContent: FunctionComponent<Props> = ({ searchValue }) => {
  const { t } = useTranslation(undefined, { keyPrefix: `store.all-products` });
  const { products, isLoading, error } = useGetAllProducts();

  const [filteredProducts, setFilteredProducts] = useState<Product[] | null>(
    null
  );

  useEffect(() => {
    if (products) {
      const filtered = filter(products, searchValue);
      setFilteredProducts(filtered);
    }
  }, [products, searchValue]);

  const renderError = (type: string) => {
    return <ErrorComponent message={t(`load.${type}.message`)} />
  }

  if (isLoading)
    return <LoadingComponent message={t("load.message")} />

  if (error)
    return renderError('error')

  if (products?.length === 0)
    return renderError('none')

  return (
    <div data-testid={`product-content`} className="flex flex-row flex-wrap gap-6">
      {filteredProducts?.map((product: Product) => (
        <ProductCard product={product} key={product.code} />
      ))}
    </div>
  );
}

export default ProductContent;