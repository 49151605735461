import { Input } from "@destination/components";
import { useUserContext } from "@hooks/useUserContext";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const UserAccountInformation: FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'account' });
  const { user } = useUserContext();

  return (
    <div className="flex flex-col gap-8 mt-8">
      <Input id="name" label={t('displayName.label')} data-testid="name" value={user.name} disabled />
      <Input id="email" label={t('email.label')} data-testid="email" value={user.email} disabled />
    </div>
  );
}

export default UserAccountInformation;