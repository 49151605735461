import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';

import { useMsal } from "@azure/msal-react";

import { Sidebar } from '@destination/components';
import { useUserContext } from "@hooks/useUserContext";
import { ReactComponent as SettingIcon } from "@icons/settings.svg";
import { ReactComponent as ApplicationIcon } from "@icons/application.svg";
import { ReactComponent as OrganizationIcon } from "@icons/organization.svg";
import { ReactComponent as DocumentationIcon } from "@icons/documentation.svg";
import { ReactComponent as HomeIcon } from "@icons/home.svg";
import { ReactComponent as SignOutIcon } from "@icons/signOut.svg";
import { ReactComponent as StorefrontIcon } from "@icons/storefront.svg";
import { ReactComponent as SupportIcon } from "@icons/support.svg";

const documentationUrl = import.meta.env.VITE_APP_DOCUMENTATION_URL as string;

export const MenuNavigation = () => {
  const { user } = useUserContext();
  const { t } = useTranslation();
  const navigationItems = useMemo(() => {
    const items = [{
      icon: <HomeIcon />,
      label: t("home.nav_item.label"),
      url: "/",
      id: "home"
    },
    {
      icon: <OrganizationIcon />,
      label: t("organization.nav_item.label"),
      url: "/organization",
      id: "organization"
    },
    {
      icon: <ApplicationIcon />,
      label: t("applications.nav_item.label"),
      url: "/applications",
      id: "applications"
    }];
    if (user.isAdministrator) {
      items.push({
        icon: <StorefrontIcon />,
        label: t("store.nav_item.label"),
        url: "/store",
        id: "store"
      });
    }
    return items;
  }, [t, user]);

  return <Navigation items={navigationItems} />;
};

export const FooterNavigation = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const handleLogout = () => {
    void instance.logout();
  };

  const footerItems = [
    {
      icon: <SupportIcon />,
      label: t("support.nav_item.label"),
      url: "/support",
      id: "support"
    },
    {
      icon: <SettingIcon />,
      label: t("settings.nav_item.label"),
      url: "/settings",
      id: "settings"
    },
    {
      icon: <DocumentationIcon />,
      label: t("documentation.nav_item.label"),
      url: documentationUrl,
      target: "_blank",
      id: "documentation"
    },
    {
      icon: <SignOutIcon />,
      label: t("signOut.nav_item.label"),
      url: "/signOut",
      id: "signOut",
      onMenuClick: handleLogout
    }
  ];

  return <Navigation items={footerItems} />;
};

const Navigation = ({
  items
}: {
  items: { id: string, icon: React.ReactNode; label: string; url: string, onMenuClick?: () => void, target?: string }[];
}) => {

  return (
    <nav className="flex h-full flex-col justify-between">
      {items.map(({ icon, id, label, target, url, onMenuClick }) => (
        <NavLink key={`navigation-${id}`} to={url} data-testid={`navigation-${id}`} target={target}
          onClick={() => onMenuClick ? onMenuClick() : undefined}>
          {({ isActive }) => (
            <Sidebar.MenuItem
              icon={icon}
              label={label}
              isActive={isActive}
            />
          )}
        </NavLink>
      ))}
    </nav>
  );
};
