import { FC } from "react";

import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";

import { Card, ITab, TabMenu } from "@destination/components";

import { useTabIndex } from "@hooks/useTabIndex";

const SettingsTabMenu: FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "settings" });

  const renderCardContent = (id: string) => {
    return <Card className="rounded-tl-none max-w-md" data-testid={`tab-${id}`}>
      <Outlet />
    </Card>;
  };
  const tabs: ITab[] = [
    {
      id: "account",
      label: t("account_tab.label"),
      content: renderCardContent('account'),
    },
    {
      id: "language",
      label: t("language_tab.label"),
      content: renderCardContent('language'),
    },
    {
      id: "notification",
      label: t("notifications_tab.label"),
      content: renderCardContent('notification'),
    },
  ];

  const { currentTabIndex } = useTabIndex(tabs);
  const navigate = useNavigate();
  const handleChangeTab = (tabIndex: number) => {
    if (tabIndex === 0) {
      return navigate("");
    }

    navigate(tabs[tabIndex].id.toLowerCase());
  };

  return (
    <TabMenu value={currentTabIndex} onChange={handleChangeTab} tabs={tabs} />
  );
};

export default SettingsTabMenu;