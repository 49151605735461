import { useMemo } from "react";

import useSWR from "swr";

import { fetcher } from "@utils/swrUtils";

import { userEndpoints } from "./endpoints";
import { DefaultOrganizationResponse } from "@account/models/DefaultOrganizationResponse";

export function useGetDefaultOrganization() {
  const url = userEndpoints.getDefaultOrganization();

  const { data, error, isLoading } = useSWR<DefaultOrganizationResponse, Error>(
    url,
    fetcher
  );

  return useMemo(
    () => ({ defaultOrganizationId: data?.organizationId, isLoading, error }),
    [data, isLoading, error]
  );
}
