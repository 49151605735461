import { handleError } from "@utils/axios-utils";
import { ApiResponse } from "./ApiResponse";
import { axiosInstance } from "@utils/axiosInstance";

export const putData = async <TRequest = unknown, TResponse = unknown>(
  url: string,
  data: TRequest
): Promise<ApiResponse<TResponse>> => {
  try {
    const response = await axiosInstance.put<TResponse>(url, data);

    return {
      statusCode: response.status,
      data: response.data,
      isSuccess: true,
    };
  } catch (error) {
    return handleError<TResponse>(error as Error);
  }
};
