import { FunctionComponent, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button, useAlert } from "@destination/components";

import ApplicationAssociations from "@users/applicationuser/components/ApplicationAssociations";
import { useInviteApplicationUser } from "@users/applicationuser/services/ApplicationUserService";
import { useInviteOrganizationUser } from "@users/organizationuser/services/OrganizationUserService";
import { AssociatedApplicationRoles } from "../models/AssociatedApplicationRoles";
import DisplayName from "./DisplayName";
import OrganizationRoleDropDown from "./OrganizationRoleDropDown";
import UserEmail from "./UserEmail";
import { useOrganizationContext } from "@hooks/useOrganizationContext";
import sendIcon from "@icons/send.svg";

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
}

const InviteUserForm: FunctionComponent<Props> = ({ onCancel, onSuccess }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "organization.users",
  });
  const { t: tUser } = useTranslation(undefined, {
    keyPrefix: "user",
  });
  const { t: tApplication } = useTranslation(undefined, {
    keyPrefix: "application.users",
  });
  const { t: tCommon } = useTranslation();

  const { organization } = useOrganizationContext();

  const { invite: inviteApplicationUser } = useInviteApplicationUser();
  const { notifyError, notifySuccess } = useAlert();
  const { invite } = useInviteOrganizationUser();
  const [displayName, setDisplayName] = useState<string>("");
  const [isValidDisplayName, setIsValidDisplayName] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [organizationRole, setOrganizationRole] = useState<string>("");
  const [isValidOrganizationRole, setIsValidOrganizationRole] =
    useState<boolean>(false);

  const [isBusy, setIsBusy] = useState<boolean>(false);

  const [selectedApplications, setSelectedApplications] = useState<
    AssociatedApplicationRoles[]
  >([]);
  const [isValidRoles, setIsValidRoles] = useState<boolean>(true);

  const saveChanges = () => {
    setIsBusy(true);
    inviteUser()
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        notifyError({
          header: t("invite.error.message", {
            user: displayName,
            organization: organization.name,
          }),
          "data-testid": 'invite-user-save-error'
        });
      })
      .finally(() => {
        setIsBusy(false);
      });
  };

  const inviteUser = async (): Promise<void> => {
    if (
      !isValidEmail ||
      !isValidDisplayName ||
      !isValidOrganizationRole ||
      !isValidRoles
    ) {
      return;
    }
    const apiResponse = await invite(
      {
        DisplayName: displayName,
        EmailAddress: email,
        OrganizationRole: organizationRole
      }
    );

    if (apiResponse.isSuccess) {
      notifySuccess({
        header: t("invite.success.message", {
          user: displayName,
          organization: organization.name,
        }),
        "data-testid": 'invite-user-save-success'
      });

      await associateSelectedApps(apiResponse.data!.userId);
    } else {
      notifyError({
        header: t("invite.error.message", {
          user: displayName,
          organization: organization.name,
          reason: apiResponse.errorMessage
        }),
        "data-testid": 'invite-user-save-error'
      });
    }
  };

  const associateSelectedApps = async (userId: string) => {
    for (const item of selectedApplications) {
      const { isSuccess } = await inviteApplicationUser(
        item.applicationId,
        userId,
        item.userRoles
      );
      if (!isSuccess) {
        notifyError({
          header: tApplication("add.error.message", {
            user: displayName,
            application: item.applicationName,
          }),
          "data-testid": 'associate-application-user-save-error'
        });
      }
      else {
        notifySuccess({
          header: tApplication("add.success.message", {
            user: displayName,
            application: item.applicationName,
          }),
          "data-testid": 'associate-application-user-save-success'
        });
      }
    }
  };

  function handleDisplayNameChange(
    newValue: string,
    isValid: boolean,
    errorMessage?: string
  ): void {
    setDisplayName(newValue);
    setIsValidDisplayName(isValid);
    if (errorMessage)
      console.log(errorMessage);
  }

  function handleEmailChange(
    newValue: string,
    isValid: boolean,
    errorMessage?: string
  ): void {
    setEmail(newValue);
    setIsValidEmail(isValid);
    if (errorMessage)
      console.log(errorMessage);
  }

  function handleRoleChange(newValue: string, isValid: boolean) {
    setOrganizationRole(newValue);
    setIsValidOrganizationRole(isValid);
  }

  function handleAppRoleChange(
    applicationId: string,
    applicationName: string,
    applicationRoles: string[],
    isValid: boolean
  ) {
    const selectedAppRole = new AssociatedApplicationRoles(
      applicationId,
      applicationName,
      applicationRoles
    );

    setSelectedApplications((current) => {
      const applications = [...current];

      const index = applications.findIndex(
        (item) => item.applicationId === applicationId
      );
      if (index > -1) applications.splice(index, 1);

      applications.push(selectedAppRole);

      return applications;
    });

    setIsValidRoles(isValid);
  }

  return (
    <div className="flex flex-col gap-10" data-testid="invite-user-content">
      <DisplayName
        id="displayName"
        label={tUser("display-name.label")}
        value={displayName}
        disabled={isBusy}
        onInputChange={handleDisplayNameChange}
      ></DisplayName>
      <UserEmail
        id="email"
        label={tUser("email.label")}
        value={email}
        disabled={isBusy}
        onInputChange={handleEmailChange}
      ></UserEmail>
      <OrganizationRoleDropDown
        label={tUser("role.label")}
        disabled={isBusy}
        selectedRole={organizationRole}
        onRoleChange={handleRoleChange}
      ></OrganizationRoleDropDown>
      <ApplicationAssociations
        onAppRoleChange={handleAppRoleChange}
        isDisabled={isBusy}
      />
      <div className="flex flex-row-reverse gap-4">
        <Button
          data-testid="save"
          variant="primary"
          isLoading={isBusy}
          onClick={saveChanges}
          disabled={
            !isValidDisplayName ||
            !isValidEmail ||
            !isValidOrganizationRole ||
            !isValidRoles
          }
          icon={<img src={sendIcon} alt="send invite" />}
        >
          {tCommon("button.save.label")}
        </Button>
        <Button data-testid="cancel" onClick={() => onCancel()} disabled={isBusy}>
          {tCommon("button.cancel.label")}
        </Button>
      </div>
    </div>
  );
};

export default InviteUserForm;
