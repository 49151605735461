import axios from "axios";

import { ApiResult } from "@api/models/ApiResult";
import { axiosInstance } from "@utils/axiosInstance";

async function deleteData(url: string) {
  try {
    const response = await axiosInstance.delete<ApiResult>(url);
    if (response.data?.errorMessage) {
      return { error: response.data?.errorMessage };
    }
    return { response: response.data, error: undefined };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error %d: %o", error.status, error.response);
      return { error: error.message };
    } else if (error instanceof Error) {
      console.error("Error: %o", error.message);
      return { error: error.message };
    } else {
      console.error("Error: %o", error);
      return { error: "Unknown error" };
    }
  }
}

export default deleteData;
