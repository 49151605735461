import { useMemo } from "react";

import useSWR from "swr";

import { organizationEndpoints } from "@api/Endpoints";
import { OrganizationDetails } from "@organization/models/OrganizationDetails";
import { fetcher } from "@utils/swrUtils";

export function useGetOrganizationDetails() {
  const url = organizationEndpoints.organizationDetails();

  const { data, error, isLoading } = useSWR<OrganizationDetails, Error>(
    url,
    fetcher
  );

  return useMemo(
    () => ({ organization: data, isLoading, error }),
    [data, isLoading, error]
  );
}
