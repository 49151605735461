export class User {
  constructor(
    id: string,
    name: string,
    roleName: string,
    language: string,
    email: string
  ) {
    this.id = id;
    this.name = name;
    this.roleName = roleName;
    this.language = language;
    this.email = email;
  }

  readonly id: string;
  readonly name: string;
  readonly roleName: string;
  readonly language: string;
  readonly email: string;

  get isAdministrator(): boolean {
    return this.roleName === "Organization Admin";
  }
}
