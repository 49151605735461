import { FC } from "react";

import { useTranslation } from "react-i18next";

import PageTitle from "@components/PageTitle";
import SettingsTabMenu from "@account/components/SettingsTabMenu";

const SettingsPage: FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "settings" });
  return (
    <>
      <PageTitle title={t("page_title")} />
      <div className="pt-8">
        <SettingsTabMenu />
      </div>
    </>
  );
};

export default SettingsPage;
