import { useCallback, useMemo } from "react";
import useSWR, { useSWRConfig } from "swr";

import { subscriptionEndPoints } from "@api/Endpoints";
import deleteData from "@hooks/useDeleteData";
import { Subscription } from "@store/subscriptions/models/Subscription";
import {
  PropertyType,
  SortDirection,
  getTimeValue,
  sortValues,
} from "@utils/sortUtil";
import { fetcher } from "@utils/swrUtils";
import { SubscriptionsResponse } from "../models/SubscriptionsResponse";

export function useCancelSubscription() {
  const { mutate } = useSWRConfig();

  const cancelSubscription = useCallback(
    async (subscriptionId: string) => {
      const url = subscriptionEndPoints.cancelSubscription(subscriptionId);
      const result = await deleteData(url);

      await mutate(subscriptionEndPoints.getSubscriptions());
      return result;
    },
    [mutate]
  );

  return useMemo(() => ({ cancelSubscription }), [cancelSubscription]);
}

export function useGetSubscriptions() {
  const url = subscriptionEndPoints.getSubscriptions();

  const { data, error, isLoading } = useSWR<SubscriptionsResponse, Error>(
    url,
    fetcher
  );

  return useMemo(
    () => ({ subscriptions: data?.subscriptions, isLoading, error }),
    [data, isLoading, error]
  );
}

export function useGetSubscriptionById(subscriptionId: string) {
  const url = subscriptionEndPoints.getSubscriptionById(subscriptionId);

  const { data, error, isLoading } = useSWR<Subscription, Error>(url, fetcher);

  return useMemo(
    () => ({ subscription: data, isLoading, error }),
    [data, isLoading, error]
  );
}

export function filter(
  subscriptions: Subscription[] | undefined,
  value: string | undefined
): Subscription[] {
  if (!subscriptions) return [];

  if (!value) return subscriptions;

  value = value.toLocaleLowerCase();

  return subscriptions.filter(
    (item) =>
      value &&
      (item.item.productName.toLocaleLowerCase().includes(value) ||
        item.billingCycle.toLocaleLowerCase().includes(value) ||
        new Date(item.nextBillingDate).toDateString().includes(value) ||
        new Date(item.createdDate).toDateString().includes(value))
  );
}

export function sort(
  subscriptions: Subscription[] | undefined,
  activeSort: string | undefined,
  sortDirection: SortDirection
): Subscription[] {
  return sortValues(subscriptions, activeSort, sortDirection, getSortValues);
}

function getSortValues(activeSort: string, a: Subscription, b: Subscription) {
  let valueA: PropertyType;
  let valueB: PropertyType;
  switch (activeSort) {
    case "product-name":
      valueA = a.item.productName;
      valueB = b.item.productName;
      break;
    case "created-date":
      valueA = getTimeValue(new Date(a.createdDate));
      valueB = getTimeValue(new Date(b.createdDate));
      break;
    case "next-billing-date":
      valueA = getTimeValue(new Date(a.nextBillingDate));
      valueB = getTimeValue(new Date(b.nextBillingDate));
      break;
    case "status":
      valueA = a.status;
      valueB = b.status;
      break;
  }
  return { valueA, valueB };
}
