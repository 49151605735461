import { storeEndPoints } from "@api/Endpoints";

import { Product } from "@store/products/models/Product";
import { ProductResponse } from "@store/products/models/ProductResponse";
import { fetcher } from "@utils/swrUtils";
import { useMemo } from "react";
import useSWR from "swr";

export function useGetAllProducts() {
  const url = storeEndPoints.getAllProducts();

  const { data, error, isLoading } = useSWR<ProductResponse, Error>(
    url,
    fetcher
  );

  return useMemo(
    () => ({ products: data?.products, isLoading, error }),
    [data, isLoading, error]
  );
}

export function useGetSuggestedProducts() {
  const url = storeEndPoints.getSuggestedProducts();

  const { data, error, isLoading } = useSWR<Product[], Error>(url, fetcher);

  return useMemo(
    () => ({ products: data, isLoading, error }),
    [data, isLoading, error]
  );
}

export function filter(
  products: Product[] | undefined,
  value: string | undefined
): Product[] {
  if (!products) return [];

  if (!value) return products;

  const lowerCasedValue = value.toLocaleLowerCase();
  return products.filter(
    (item) =>
      item.name.toLocaleLowerCase().includes(lowerCasedValue) ||
      item.description.toLocaleLowerCase().includes(lowerCasedValue) ||
      item.about.toLocaleLowerCase().includes(lowerCasedValue)
  );
}
